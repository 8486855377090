import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SignOut from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
// import Dashboard from '@material-ui/icons/Dashboard';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import MaterialLink from '@material-ui/core/Link';
import Column from 'mirage-components/dist/Column';
import { hasPermission } from 'mirage-api-client/dist';
import { useUser } from 'mirage-api-client/dist/user';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { trialDaysRemaining, isTrialExpired } from '../lib/utils';
import { accountSuspended, parentAccountSuspended } from '../lib/ErrorMessages';
import firebase from '../entities/firebase';
import logo from '../assets/logo.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  alert: {
    borderRadius: 0,
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    flex: 1,
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[100],
  },
  toolbar: {
    minHeight: 48,
  },
  doubleToolbar: {
    minHeight: 96,
  },
  menuButton: {
    marginRight: 36,
  },
  mobileCloseMenuButton: {
    marginLeft: 5,
  },
  hide: {
    display: 'none',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  listItem: {
    paddingLeft: 24,
    whiteSpace: 'nowrap',
  },
  drawerTopRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 150,
    position: 'absolute',
    left: `calc(50vw - 75px)`,
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  welcome: {
    minHeight: 'unset !important',
  },
  userBox: {
    padding: `5px 24px`,
  },
  userEmail: {
    marginTop: -4,
  },
  addPaymentLink: {
    color: theme.palette.primary.contrastText,
  },
}));

const menuButtons = [
  // {
  //   label: 'Home',
  //   icon: <Dashboard />,
  //   href: '/',
  // },
  {
    label: 'Identities',
    icon: <SupervisedUserCircle />,
    href: '/identities',
    flag: 'identityDropdown',
  },
  {
    label: 'Users',
    icon: <SupervisorAccount />,
    href: '/users',
    permissions: 'user.write',
    flag: 'accountManagement',
  },
  {
    label: 'Billing',
    icon: <ReceiptIcon />,
    href: '/billing',
    permissions: 'nonExistentPath',
    flag: 'accountPortalBilling',
  },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    href: '/settings',
  },
  {
    label: 'Sign Out',
    icon: <SignOut style={{ transform: 'rotate(180deg)' }} />,
    onClick: () => firebase.auth().signOut(),
  },
];

export default function Layout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [user] = useUser();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (matches) handleDrawerOpen();
    else handleDrawerClose();
  }, [matches]);

  return (
    <div className={classes.root}>
      <TopBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <DrawerMenu open={open} close={handleDrawerClose} />
      <main className={classes.content}>
        <div
          className={`${classes.toolbar} ${
            user && user.status === 'trial' ? classes.doubleToolbar : ''
          }`}
        />
        {children}
      </main>
    </div>
  );
}

function TopBar({ open, handleDrawerOpen, handleDrawerClose }) {
  const classes = useStyles();
  const [user] = useUser();

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBar}
        color="default"
        elevation={1}
      >
        {user && user.status === 'trial' && (
          <Alert
            severity={
              isTrialExpired(user.trialExpiration) ? 'error' : 'warning'
            }
            elevation={0}
            variant="filled"
            className={classes.alert}
          >
            {isTrialExpired(user.trialExpiration)
              ? `Your trial has expired.`
              : `You have ${trialDaysRemaining(
                  user.trialExpiration
                )} days remaining in
            your trial.`}{' '}
            {!user.parentUID ? (
              <Link to="/settings">
                <MaterialLink
                  className={classes.addPaymentLink}
                  component="span"
                  underline="always"
                >
                  Add a payment method to continue using Mirage ID
                </MaterialLink>
              </Link>
            ) : (
              'To continue using Mirage, please ask your administrator to add a payment method.'
            )}
          </Alert>
        )}

        {user && user.status === 'suspended' && (
          <Alert
            severity="error"
            elevation={0}
            variant="filled"
            className={classes.alert}
          >
            {!user.parentUID ? parentAccountSuspended : accountSuspended}
          </Alert>
        )}

        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label={open ? 'open drawer' : 'close drawer'}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Mirage ID" className={classes.logo} />
        </Toolbar>
      </AppBar>
    </>
  );
}

function DrawerMenu({ open, close }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [user] = useUser();
  const flags = useFlags();
  if (!user) return null;
  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      open={matches ? true : open}
      onClose={close}
      variant={matches ? 'permanent' : 'temporary'}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div
        className={`${classes.toolbar} ${
          user && user.status === 'trial' ? classes.doubleToolbar : ''
        }`}
      >
        {!matches && (
          <IconButton
            onClick={close}
            color="inherit"
            aria-label="close drawer"
            className={classes.mobileCloseMenuButton}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Divider />
      </div>

      <Collapse in={open} classes={{ entered: classes.welcome }}>
        <div className={classes.userBox}>
          <Typography variant="caption" color="textSecondary">
            Welcome
          </Typography>
          <Typography
            color="textSecondary"
            className={classes.userEmail}
            noWrap
          >
            {user.email}
          </Typography>
        </div>
        <Divider />
      </Collapse>
      <List disablePadding className={classes.listColumn}>
        {menuButtons.map(
          (
            { label, icon, onClick, href, permissions, flag = null },
            index,
            buttons
          ) => {
            const active = href && pathname === href;
            if (permissions && !hasPermission(user, permissions)) {
              return null;
            }
            if (flag && !flags[flag]) {
              return null;
            }

            return index === buttons.length - 1 ? (
              <Column
                key={label}
                style={{ flex: 1, justifyContent: 'flex-end' }}
              >
                <Divider />
                <MenuButton
                  label={label}
                  icon={icon}
                  onClick={onClick}
                  href={href}
                  active={active}
                  mobile={!matches}
                  close={close}
                />
              </Column>
            ) : (
              <MenuButton
                label={label}
                key={label}
                icon={icon}
                onClick={onClick}
                href={href}
                active={active}
                mobile={!matches}
                close={close}
              />
            );
          }
        )}
      </List>
    </Drawer>
  );
}

function MenuButton({ label, icon, onClick, href, active, mobile, close }) {
  const classes = useStyles();
  return (
    <ListItem
      onClick={(...args) => {
        if (typeof onClick === 'function') onClick(...args);
        if (mobile) close();
      }}
      to={href}
      button
      component={href ? Link : undefined}
      key={label}
      classes={!mobile ? { root: classes.listItem } : undefined}
      selected={active}
    >
      <ListItemIcon alt={label}>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}
