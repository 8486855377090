import React from 'react';
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';
import BillingView from 'mirage-components/dist/billing/View';
import { billing } from 'mirage-api-client';
import { useUser } from 'mirage-api-client/dist/user';

const useStyles = makeStyles({
  buttonRow: {
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  backdrop: {
    flex: 1,
    zIndex: 1,
  },
  noIdentities: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 80,
  },
  fab: {
    position: 'fixed',
    right: 16,
    bottom: 16,
    zIndex: 100,
  },
});

export default function Billing() {
  const [user] = useUser();
  const classes = useStyles();
  const [bill, setBill] = React.useState(false);
  const [periods, setPeriods] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      billing.periods(user.uid).then(setPeriods);
      billing.get(user.uid).then(setBill);
    }
  }, [user]);

  const onChangePeriod = periodId => {
    setBill(false);
    billing.get(user.uid, periodId).then(setBill);
  };

  if (user && bill && periods) {
    return (
      <BillingView
        bill={bill}
        periods={periods}
        onChangePeriod={onChangePeriod}
        title="Identity Usage"
      />
    );
  }

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
