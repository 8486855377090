import React from 'react';
import Container from '@material-ui/core/Container';
import Billing from '../components/Billing';

export default function BillingPage() {
  return (
    <Container>
      <Billing />
    </Container>
  );
}
