import React from 'react';
import Container from '@material-ui/core/Container';
import Settings from '../components/Settings';

export default function BillingPage() {
  return (
    <Container>
      <Settings />
    </Container>
  );
}
